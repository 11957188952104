//全体共通で使うCSS定義
body{
  word-wrap: break-word;
}

a{}
p{}
h1{}
h2{}
h3{}
h4{}
h5{}
