//サイトレイアウト構造を記述

#container{}
#main{}
#contents{
  width: $contentWidth;
  margin: 0 auto;
}

[class*="grid"] {
  @include mq-max(md) {
    margin: 0;
  }
}
